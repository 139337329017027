import React,{useState} from 'react'
import ComparatifAccordeonDesktop from "./ComparatifAccordeonDesktop.js"
import LignAccDesk from './LignAccDesk.js'
import CommingSoon from './CommingSoon.js'
import Footer from './Footer.js'
export default function CompDeskContent(props) {
    const [popup, setPopup] = useState(false);
    const toggle = ()=>{
        setPopup(!popup);
    }
  if(props.cible === 'professionnels'){
    return(
    <div>
        <CommingSoon popup={popup} toggle={toggle}/>
        <div className='flex flex-col slide-in-right'>
            <h2 className='w-[38rem] h-fit mx-auto font-Poppins text-[3rem] tracking-[0.2875rem] leading-[3.5rem] pb-0'>
            Choisissez l’offre qui correspond le mieux <span className='text-pink-red'>à votre entreprise</span>
            </h2>
            <p className='mx-auto font-DMSans text-[0.75rem] mt-4'>*certaines offres sont soumises à des critères d’éligibilité</p>
            <div className='w-full flex flex-row justify-end px-[6rem] mt-24'>
                <div className='flex flex-col items-start text-left w-[18rem] mr-16'>
                    <h3 className='font-Poppins text-[2rem] font-semibold uppercase '>prestige pro</h3>
                    <p className='font-Poppins text-[#7F7F7F] text-[0.9rem]'>Pour nos  clients les plus exceptionnels</p>
                    <div className='gradient-container h-[12rem] w-full p-2 mt-2 -translate-x-2'>
                     <img alt='carte premium' className='w-full h-full' src='carte-dupont.png'></img>
                    </div>
                    <div className='flex flex-row justify-between w-full mt-4'>
                        <p className='font-Poppins'>79,00€</p>
                        <a onClick={toggle} className='font-Poppins underline text-pink-red font-semibold pr-2 cursor-pointer'>Ouvrir un compte</a>
                    </div>
                </div>

                <div className='flex flex-col items-start text-left w-[18rem]'>
                    <h3 className='font-Poppins text-[2rem] font-semibold uppercase '>premium pro</h3>
                    <p className='font-Poppins text-[#7F7F7F] text-[0.9rem]'>Pour nos  clients les plus exigeants.</p>
                    <div className='gradient-container h-[12rem] w-full p-2 mt-2 -translate-x-2'>
                     <img alt='carte premium' className='w-full h-full' src='carte-dupont-premium.png'></img>
                    </div>
                    <div className='flex flex-row justify-between w-full mt-4'>
                        <p className='font-Poppins'>49,00€</p>
                        <a onClick={toggle} className='font-Poppins underline text-pink-red font-semibold pr-2 cursor-pointer'>Ouvrir un compte</a>
                    </div>
                </div>

            </div>
            <ComparatifAccordeonDesktop open={true} title='Comptes et opérations'>
                <LignAccDesk colored={false} title='Compte en euros' subtitle='' prestige='img' premium='img'/>
                <LignAccDesk colored={false} title='Paiement sortant en euros (SEPA)' subtitle='' prestige='img' premium='img'/>
                <LignAccDesk colored={false} title='Paiement instantanné en euros (SEPA)' subtitle=''  prestige='img' premium='img'/>
                <LignAccDesk colored={false} title='Paiement programmés et récurrents' subtitle=''  prestige='img' premium='img'/>
                <LignAccDesk colored={false} title='Paiement en devise' subtitle='' prestige='Plus de 30 devises' premium='Plus de 30 devises'/>
            </ComparatifAccordeonDesktop>
            <ComparatifAccordeonDesktop open={false} title='Services à valeur ajoutée'>
                <LignAccDesk colored={false} title="Programme fidélité B Partner" subtitle='' prestige='img' premium='img'/>
                <LignAccDesk colored={false} title='Conciergerie' subtitle='' prestige='img' premium='img'/>
                <LignAccDesk colored={false} title='Accès lounge, Priority Pass' subtitle=''  prestige='img' premium='img'/>
                <LignAccDesk colored={false} title='Dépannage cash à l’étranger' subtitle=''  prestige='2%' premium='2%'/>
            </ComparatifAccordeonDesktop>
            <ComparatifAccordeonDesktop open={false} title='Services prestiges'>
                <LignAccDesk colored={false} title="Assistant personnel" subtitle='' prestige='img' premium='croix'/>
                <LignAccDesk colored={false} title='Conseiller financier personnel' subtitle='' prestige='img' premium='croix'/>
                <LignAccDesk colored={false} title="Bilan E-reputation annuelle" subtitle='' prestige='img' premium='croix'/>
            </ComparatifAccordeonDesktop>
            <ComparatifAccordeonDesktop open={false} title='Paiement au quotidien'>
                <LignAccDesk colored={false} title="Paiements en zone euro gratuits" subtitle='' prestige='img' premium='img'/>
                <LignAccDesk colored={false} title='Carte virtuelle gratuite' subtitle='' prestige='Illimité' premium='10 cartes offertes'/>
                <LignAccDesk colored={false} title='Plafonds de paiement' subtitle=''  prestige='Gestion en ligne' premium='Gestion en ligne'/>
                <LignAccDesk colored={false} title='Carte de débit VISA internationale' subtitle=''  prestige='Métal' premium='PVC (recyclable)'/>
                <LignAccDesk colored={false} title='Paiements hors zone euro' subtitle='' prestige='img' premium='img'/>
            </ComparatifAccordeonDesktop>
            <ComparatifAccordeonDesktop open={false} title='Retraits et services'>
                <LignAccDesk colored={false} title='Cash-back pour tous les paiements' subtitle='' prestige='Jusqu’à 1,5%' premium='Jusqu’à 1%'/>
                <LignAccDesk colored={false} title='Retraits en zone France' subtitle=''  prestige='10 gratuits' premium='5 gratuits'/>
            </ComparatifAccordeonDesktop>
            <ComparatifAccordeonDesktop open={false} title='Services pour professionels'>
                <LignAccDesk colored={false} title="Terminaux de paiements m-POS" subtitle='' prestige='Sur demande' premium='Sur demande'/>
                <LignAccDesk colored={false} title='Outils d’analyse' subtitle='' prestige='fin 2024' premium='fin 2024'/>
                <LignAccDesk colored={false} title='Emission de factures' subtitle=''  prestige='fin 2024' premium='fin 2024'/>
                <LignAccDesk colored={false} title='Lien de paiement par SMS ou Email' subtitle=''  prestige='fin 2024' premium='fin 2024'/>
            </ComparatifAccordeonDesktop>
        </div>
        <p className='text-left pl-20 mt-12 font-DMSans text-[1.25rem]'>Pour en savoir plus, vous pouvez télécharger nos Conditions Tarifaires <a className='text-pink-red underline cursor-pointer' href='conditionTarifPro.pdf' target='_blank'>ici</a></p>
    </div>
    )
  }else{
    return(
    <div>
        <CommingSoon popup={popup} toggle={toggle}/>
        <div className='flex flex-col slide-in-left'>
            <h2 className='w-[46rem] h-fit mx-auto font-Poppins text-[3rem] tracking-[0.2875rem] leading-[3.5rem] pb-0'>
            Choisissez l’offre qui vous<span className='text-pink-red'>{' '}correspond le mieux</span>
            </h2>
            <p className='mx-auto font-DMSans text-[0.75rem] mt-4'>*certaines offres sont soumises à des critères d’éligibilité</p>
            <div className='w-full flex flex-row justify-end px-[6rem] mt-24'>
                <div className='flex flex-col items-start text-left w-[18rem] mr-16'>
                    <h3 className='font-Poppins text-[2rem] font-semibold uppercase '>prestige</h3>
                    <p className='font-Poppins text-[#7F7F7F] text-[0.9rem]'>Pour nos  clients les plus exceptionnels</p>
                    <div className='gradient-container h-[12rem] w-full p-2 mt-2 -translate-x-2'>
                     <img alt='carte premium' className='w-full h-full' src='carte-dupont.png'></img>
                    </div>
                    <div className='flex flex-row justify-between w-full mt-4'>
                        <p className='font-Poppins'>39,00€</p>
                        <a onClick={toggle} className='font-Poppins underline text-pink-red font-semibold pr-2 cursor-pointer'>Ouvrir un compte</a>
                    </div>
                </div>

                <div className='flex flex-col items-start text-left w-[18rem]'>
                    <h3 className='font-Poppins text-[2rem] font-semibold uppercase '>premium</h3>
                    <p className='font-Poppins text-[#7F7F7F] text-[0.9rem]'>Pour nos  clients les plus exigeants</p>
                    <div className='gradient-container h-[12rem] w-full p-2 mt-2 -translate-x-2'>
                     <img alt='carte premium' className='w-full h-full' src='carte-dupont-premium.png'></img>
                    </div>
                    <div className='flex flex-row justify-between w-full mt-4'>
                        <p className='font-Poppins'>18,00€</p>
                        <a onClick={toggle} className='font-Poppins underline text-pink-red font-semibold pr-2 cursor-pointer'>Ouvrir un compte</a>
                    </div>
                </div>

            </div>
            <ComparatifAccordeonDesktop open={true} title='Comptes et opérations'>
                <LignAccDesk colored={false} title='Compte en euros' subtitle='' prestige='img' premium='img'/>
                <LignAccDesk colored={false} title='Paiement sortant en euros (SEPA)' subtitle='' prestige='img' premium='img'/>
                <LignAccDesk colored={false} title='Paiement instantanné en euros (SEPA)' subtitle=''  prestige='img' premium='img'/>
                <LignAccDesk colored={false} title='Paiement programmés et récurrents' subtitle=''  prestige='img' premium='img'/>
                <LignAccDesk colored={false} title='Paiement en devise' subtitle='' prestige='Plus de 30 devises' premium='Plus de 30 devises'/>
            </ComparatifAccordeonDesktop>
            <ComparatifAccordeonDesktop open={false} title='Services à valeur ajoutée'>
                <LignAccDesk colored={false} title="Programme fidélité B Partner" subtitle='' prestige='img' premium='img'/>
                <LignAccDesk colored={false} title='Conciergerie' subtitle='' prestige='img' premium='img'/>
                <LignAccDesk colored={false} title='Accès lounge, Priority Pass' subtitle=''  prestige='img' premium='img'/>
                <LignAccDesk colored={false} title='Dépannage cash à l’étranger' subtitle=''  prestige='2%' premium='2%'/>
            </ComparatifAccordeonDesktop>
            <ComparatifAccordeonDesktop open={false} title='Services prestiges'>
                <LignAccDesk colored={false} title="Assistant personnel" subtitle='' prestige='img' premium='croix'/>
                <LignAccDesk colored={false} title='Conseiller financier personnel' subtitle='' prestige='img' premium='croix'/>
                <LignAccDesk colored={false} title="Bilan E-reputation annuelle" subtitle='' prestige='img' premium='croix'/>
            </ComparatifAccordeonDesktop>
            <ComparatifAccordeonDesktop open={false} title='Paiement au quotidien'>
                <LignAccDesk colored={false} title="Paiements en zone euro gratuits" subtitle='' prestige='img' premium='img'/>
                <LignAccDesk colored={false} title='Carte virtuelle gratuite' subtitle='' prestige='Illimité' premium='5 cartes offertes'/>
                <LignAccDesk colored={false} title='Plafonds de paiement' subtitle=''  prestige='Gestion en ligne' premium='Gestion en ligne'/>
                <LignAccDesk colored={false} title='Carte de débit VISA internationale' subtitle=''  prestige='Métal' premium='PVC (recyclable)'/>
                <LignAccDesk colored={false} title='Paiements hors zone euro' subtitle='' prestige='img' premium='img'/>
            </ComparatifAccordeonDesktop>
            <ComparatifAccordeonDesktop open={false} title='Retraits et services'>
                <LignAccDesk colored={false} title='Cash-back pour tous les paiements' subtitle='' prestige='Jusqu’à 1,25%' premium='Jusqu’à 0,75%'/>
                <LignAccDesk colored={false} title='Retraits en zone France' subtitle=''  prestige='5 gratuits' premium='3 gratuits'/>
            </ComparatifAccordeonDesktop>
        </div>
        <p className='text-left pl-20 mt-12 font-DMSans text-[1.25rem]'>Pour en savoir plus, vous pouvez télécharger nos Conditions Tarifaires <a className='text-pink-red underline cursor-pointer' href='conditionTarifParticuliers.pdf' target='_blank'>ici</a></p>
    </div>
    )
  }
}
