import React,{useState} from 'react'
import TopBar from '../components/TopBar'
import JoinUsBtn from '../components/JoinUsBtn'
import Caroussel from '../components/Caroussel'
import PrestigeCard from '../components/PrestigeCard'
import Value from '../components/Value'
import CardAdvantage from '../components/CardAdvantage'
import Footer from '../components/Footer'
import CommingSoon from '../components/CommingSoon'
export default function LandingPrestige() {
    const [popup, setPopup] = useState(false);
    console.log(popup)
    const toggle = ()=>{
        setPopup(!popup);
    }
  return (
<div className='flex flex-col'>


    <TopBar />


    {/* div video */}
    <div className='relative'>
    <video
        className='object-cover w-full h-screen'
        loop
        muted ={true}
        autoPlay={true}
        playsInline
        controls={false}
      >
        <source src={'/video4.mp4'} type='video/mp4' />
        Your browser does not support the video tag.
      </video>
      <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-Poppins md:w-[43.72rem] lg:w-full lg:flex lg:flex-col lg:justify-center lg:items-center'>
        <h1 className='w-screen text-4xl leading-[3.5rem] font-normal tracking-wider font-Poppins md:w-full md:border-l-[3px] border-white md:ml-4 md:text-left md:pl-4 md:text-[3rem] md:mt-[19rem] lg:w-[45.18rem] lg:translate-x-16 lg:translate-y-14'>
        Découvrez B Partner{" "}<span className='text-pink-red'> PRESTIGE, l'excellence</span>{' '}au plus prés de vous
        </h1>
        <p className='font-Poppins tracking-wider mt-8 md:mt-[17rem] md:text-left md:text-[1.5rem] md:p-4 md:w-[39rem] lg:self-start lg:ml-[5rem] lg:-translate-y-20 px-2'>
        <span className='md:text-[2rem]'>Multi-devise sans <span className='md:text-pink-red'> {' '}frontieres<br className='hidden md:flex'/></span></span> 
        {' '} accompagné de services innovants pour accompagner votre gestion financière
        </p>
      </div>
    </div>
    <CommingSoon popup={popup} toggle={toggle}/>
    {/* reste page */}
    <div className='bg-white px-5 flex flex-col items-center mt-[2rem] lg:px-14'>
    {/* detail offres */}
    <div className='flex flex-col items-center lg:flex-row lg:w-[80.4375rem] lg:mt-12 lg:justify-around lg:items-center'>
        <div className='md:w-[48rem] md:h-[36.5rem] md:flex md:flex-row md:justify-center md:items-center md:px-[2rem] md:mt-4 lg:w-[48rem] lg:h-full lg:mt-12 lg:px-0 lg:translate-y-6'>
            {/* card 1 text */}
            <div className='w-[21.375rem] pl-1 md:w-[23rem] md:h-[27.5rem] md:z-10 md:-translate-y-6 lg:w-[20rem] lg:h-[25.5rem]'>
                <div className='w-40 h-fit bg-pink-red p-[0.5rem] md:h-[27.375rem] md:w-[11.25rem] lg:w-[10.5625rem] lg:h-[26.5rem]'>
                    <div className='w-80 bg-bp-grey h-[23.75rem] flex flex-col justify-center items-center md:h-[26.375rem] md:w-[22.0625rem] lg:w-[20.6875rem] lg:h-[25.5rem]'>
                        <div className=' w-[12rem] pl-2 lg:w-[15rem]'>
                            <h3 className='text-[1rem] lg:text-[1.25rem] text-white font-Poppins tracking-[3px] font-semibold  h-[2rem] text-left'>
                            Multi-devises
                            </h3>
                            <p className='text-white font-Poppins text-[0.5rem] lg:text-[0.75rem] tracking-[3px] leading-[1rem] text-left '>
                            Des paiements en devises simplifiés
                            </p>
                        </div>

                        <div className=' w-[12.5rem] mt-[1rem] pl-2 lg:w-[15rem]'>
                            <h3 className='text-[1rem] lg:text-[1.25rem] text-white font-Poppins tracking-[3px] font-semibold  h-[2rem] text-left'>
                            Services VIP
                            </h3>
                            <p className='text-white font-Poppins text-[0.5rem] lg:text-[0.75rem] tracking-[3px] leading-[1rem] text-left'>
                            Un service luxueux adapté à votre image
                            </p>
                        </div>

                        <div className=' w-[12.5rem] pl-2 lg:w-[15rem] lg:mt-4'>
                            <h3 className='text-[1rem] lg:text-[1.25rem] text-white font-Poppins tracking-[3px] font-semibold  h-[2rem] text-left'>
                            Confidentialité
                            </h3>
                            <p className='text-white font-Poppins text-[0.5rem] lg:text-[0.75rem] tracking-[3px] leading-[1rem] text-left '>
                            Votre sécurité est notre priorité
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {/* card 1 img */}
            <div className='w-[21rem] px-1 mt-[1rem] md:w-[27.75rem] md:h-[33.5rem] lg:w-[25.1875rem] lg:h-full lg:-translate-x-16'>
                <img className="lg:-translate-y-8" src="boat.png" alt="a boat" />
            </div>
        </div>
        {/* div nos offres */}
        <div className='w-[20.5rem] px-1 mt-[1rem] flex flex-col md:w-[38.125rem] md:-translate-x-8 justify-start md:mt-0 lg:w-[25rem] lg:pr-10 lg:-translate-x-0'>
            <p className='w-[10rem] h-[3.5rem] flex items-center font-Poppins tracking-[0.2875rem] md:w-[12.125] md:[h-2.5rem] lg:w-[14rem]   uppercase'>Pour vous
            </p>
            <p className='text-[1.5rem] lg:text-[2rem] font-normal tracking-[0.2875rem] text-left leading-[3rem] border-l-[3px] pb-4 border-black pl-[1rem]'>
            Solutions Dynamiques pour personalités d'Exception
            </p>
            <p className='w-[20.9375rem] mt-[1rem]  font-DMSans text-left p-0 md:w-full '>
            Parce que la gestion de votre carrière est unique, vos finances le sont tout autant et nécessitent une attention particulière. Notre engagement est de combiné la simplicité, la sécurité et une approche sur mesure pour vos finances.
            </p>
            <p className='w-[20.9375rem] mt-[1.5rem] mb-[1.5rem] font-DMSans text-left  p-0 md:w-full lg:mb-10'>
            Chez B Partner, nous comprenons les rythmes soutenus, et la gestion dynamique de vos dépenses et flux de paiements. Nous nous adaptons à vos besoins tout en étant disponibles pour vous conseiller au quotidien.
            </p>
            <div className='w-fit' onClick={toggle}><JoinUsBtn text='COMMENCER'/></div>
        </div>
    </div>
            <Caroussel 
            title="NOS SERVICES COMPLEMENTAIRES PRESTIGE"
            img1='img-crsl-5.jpeg'
            img2='img-crsl-6.jpeg'
            img3='img-crsl-7.jpeg'
            p1="Soyez accompagnés lors de vos voyages par votre assistant personnel"
            p2="Réalisez des paiements dans plus de 30 devises depuis votre compte"
            p3="Soyez récompensez de manière unique grâce à notre programme de fidélité"/>
            <div className='flex flex-col lg:flex lg:flex-row lg:w-[70rem] lg:mt-24 lg:justify-between lg:items-center lg:px-0 lg:mr-2'>
        <div className='md:w-[48rem] md:h-[36.5rem] md:flex md:flex-row md:justify-center md:items-center md:px-[2rem] md:mt-4 lg:w-[64rem] lg:h-full lg:mt-0 lg:px-0 order-2'>
            {/* card 1 text */}
            <div className='w-[21.375rem] mr-2 pl-1 mt-12 md:order-2 md:flex md:flex-row md:justify-end md:w-[23rem] md:-translate-y-16 lg:-translate-y-0 md:h-[27.5rem] lg:w-[20rem] lg:h-[25.5rem] z-10'>
                <div className='w-40 h-[24.75rem] bg-pink-red p-[0.5rem] md:h-[27.375rem] md:w-[11.25rem] lg:w-[10.5625rem] lg:h-[26.5rem]'>
                    <div className='w-80 bg-bp-grey h-[23.75rem] flex flex-col justify-center items-center md:-translate-x-[12rem] md:h-[26.375rem] md:w-[22.0625rem] lg:w-[20.6875rem] lg:h-[25.5rem] lg:-translate-x-[11.225rem]'>
                        <div className=' w-[16rem] pl-2 lg:w-[16rem]'>
                            <h3 className='text-[1rem] lg:text-[1.25rem] text-white font-Poppins tracking-[3px] font-semibold  h-[2rem] text-left lg:w-[15.5rem]'>
                            Autonomie
                            </h3>
                            <p className='text-white font-Poppins text-[0.5rem] lg:text-[0.75rem] tracking-[3px] leading-[1rem] text-left'>
                            Gérez vos paiements en devises depuis votre compte
                            </p>
                        </div>

                        <div className=' w-[16rem] pl-2 lg:w-[16rem] lg:mt-8'>
                            <h3 className='text-[1rem] lg:text-[1.25rem] text-white font-Poppins tracking-[3px] font-semibold  h-[2rem] text-left lg:w-[15.5rem]'>
                            Ecoute
                            </h3>
                            <p className='text-white font-Poppins text-[0.5rem] lg:text-[0.75rem] tracking-[3px] leading-[1rem] text-left'>
                            Un assistant personnel vous accompagne pour toutes vos demandes
                            </p>
                        </div>

                        <div className=' w-[16rem] pl-2 lg:w-[16rem] lg:mt-8'>
                            <h3 className='text-[1rem] lg:text-[1.25rem] text-white font-Poppins tracking-[3px] font-semibold  h-[2rem] text-left lg:w-[15.5rem]'>
                            Disponibilité
                            </h3>
                            <p className='text-white font-Poppins text-[0.5rem] lg:text-[0.75rem] tracking-[3px] leading-[1rem] text-left'>
                            Que soit en ligne, ou au téléphone nos conseillers seront vos alliés
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-[21rem] px-1 mt-[1rem] md:w-[27.75rem] md:h-[36.5rem] lg:w-[25.1875rem] lg:h-full'>
                <img className="lg:translate-x-12" src="buisnessWoman.jpeg" alt="business woman" />
            </div>
        </div>
        {/* Nos offres */}
        <div className='w-[20.5rem] px-1 mt-[1rem] flex flex-col md:w-[38.125rem] md:-translate-x-8 md:ml-16 lg:ml-4 justify-start lg:w-[26rem] lg:pr-0 order-1'>
            <p className='w-[14rem] h-[3.5rem] flex items-center font-Poppins tracking-[0.2875rem] md:w-[14rem] md:[h-2.5rem] lg:w-[16rem] lg:mb-6 uppercase'>Votre carte
            </p>
            <p className='text-[1.5rem] lg:text-[2rem] font-normal tracking-[0.2875rem] text-left leading-[3rem] border-l-[3px] pb-4 border-black pl-[1rem] '>
            Services Efficaces, pour les personnalités pressées
            </p>
            <p className='w-[20.9375rem] mt-[1rem]  font-DMSans text-left p-0 md:w-full '>
            Votre temps est précieux, tout autant que votre réputation et vos finances, il est temps d'en prendre soin. 
            </p>
            <p className='w-[20.9375rem] mt-[1.5rem] mb-[1.5rem] font-DMSans text-left  p-0 md:w-full lg:mb-10 '>
            Chez B Partner, la proximité est de mise, et nos conseillers et services à forte valeur vous seront entièrement dévoués.
            </p>
            <div className='w-fit' onClick={toggle}><JoinUsBtn text='SOUSCRIRE'/></div>
        </div>
        </div>
        <div className=' lg:flex lg:flex-row lg:w-[80rem] lg:px-16 lg:justify-around lg:mt-24'>
        <div className='w-[20.5rem] px-1 mt-[1rem] flex flex-col md:w-[38.125rem] md:-translate-x-8 md:mt-12 justify-start md:ml-8 lg:w-[26.625rem] lg:justify-center lg:mr-16'>
            <p className='w-[10rem] h-[3.5rem] flex items-center font-Poppins tracking-[0.2875rem] md:w-[12.125] md:[h-2.5rem] uppercase lg:text-xl lg:w-[14rem]'>NOS VALEURS
            </p>
            <p className='text-[1.5rem] lg:text-[2rem] font-normal tracking-[0.2875rem] text-left leading-[3rem] border-l-[3px] pb-4 border-black pl-[1rem]'>
            B Partner, l'excellence dans la finance, redéfinie 
            </p>
            <p className='w-[20.9375rem] mt-[1rem]  font-DMSans text-left p-0 md:w-full md:mb-6 lg:text-[1.125rem] mb-6'>
            L'Excellence et la qualité sont nos mots d'ordre quand il s'agit de vous aider à gérer vos finances. 
            </p>
            <p className='w-[20.9375rem]  font-DMSans text-left p-0 md:w-full md:mb-6 lg:text-[1.125rem] mb-6'>
            Nos certifications ISO renforcent nos capacités et soutiennent vos projets tout en sécurisant vos données 
            </p>
            <div className='w-fit' onClick={toggle}><JoinUsBtn text='REJOIGNEZ NOUS'/></div>
        </div>
        {/* Valeurs */}
        <div className='md:grid md:grid-cols-3 md:w-full md:mt-4 lg:w-[55%]'>
            <Value
                number='01'
                title='Securité'
                text='Conçue pour un monde en évolution.'            
            />
            <Value
                number='02'
                title='Proximité'
                text='Dans vos usages du quotidien'            
            />
            <Value
                number='03'
                title='Tranquilité'
                text="Pour gérer vos paiements et finances"            
            />
            <Value
                number='04'
                title='Confidentialité'
                text='Et protection sans concession'            
            />
            <Value
                number='05'
                title='Personalisation'
                text='Des services et produits'            
            />
            <Value
                number='06'
                title='Exclusivité'
                text='Des solutions uniques, et inédites'            
            />
        </div>
    </div>
      <div className='md:w-full md:mt-12 lg:w-[80rem] lg:mt-8'>
        <CardAdvantage img='carte-dupont.png' 
        title='Virements Multi-Devise'
        text="Vos paiements en devise depuis votre application sécurisée B Partner, en quelques clics les fonds sont transférés partout dans le monde."
        PicPosition='right'/>

        <CardAdvantage img='carte-3.png' 
        title='VOTRE PROTECTION'
        text="Notre service innovant de E-réputation permet de vous protéger ainsi que votre entreprise sur internet et les réseaux sociaux."
        PicPosition='left'/>

        <CardAdvantage img='carte-4.png' 
        title='Programme de fidélité'
        text="L’usage de vos services et produits B Partner sont récompensés avec notre programme de fidélité unique, qui vous permet entre autre de convertir vos points B Partner en cash-back"
        PicPosition='right'/>

        <CardAdvantage img='cravate.jpeg' 
        title='Vos services Prestige'
        text="Votre concierge sera à votre disposition, afin de vous accompagner dans vos démarches au quotidien et vous faciliter la vie. Profitez aussi de notre service e-Reputation afin de faire le bilan sur votre réputation en ligne et protéger votre image, et vos données."
        PicPosition='left'/>
        </div>
        <div className='flex flex-col lg:flex lg:flex-row lg:mt-12 lg:w-[80rem] lg:px-16 lg:justify-around '>
        <div className='w-[20.5rem] px-1 mt-[1rem] flex flex-col md:w-[38.125rem] md:-translate-x-8 md:mt-12 justify-start md:ml-8 lg:w-[26.625rem] md:hidden lg:flex lg:justify-center order-2 lg:order-1'>
            <p className='w-[10rem] h-[3.5rem] flex items-center font-Poppins tracking-[0.2875rem] md:w-[12.125] md:[h-2.5rem] uppercase lg:text-xl lg:w-[14rem]'>POUR VOUS
            </p>
            <p className='text-[1.5rem] lg:text-[2rem] font-normal tracking-[0.2875rem] text-left leading-[3rem] border-l-[3px] pb-4 border-black pl-[1rem]'>
            B Partner PRESTIGE: Pour  particuliers et professionnels
            </p>
            <p className='w-[20.9375rem] mt-[1rem]  font-DMSans text-left p-0 md:w-full md:mb-6 lg:text-[1.125rem] mb-6 lg:mb-10'>
            B Partner Prestige est une offre sur mesure pour les particulier et les professionnels. Les services sont conçus pour vous faciliter la vie, et vous permettre de vous concentrer sur ce qui est important pour vous. A tout moment, votre concierge B Partner sera à votre écoute, tout comme le seront nos équipes de proximité.
            </p>
            <div className='w-fit' onClick={toggle}><JoinUsBtn text='REJOIGNEZ NOUS'/></div>
        </div>
        {/* cards */}
        <div className='md:mt-12 md:flex md:flex-row order-1 lg:order-2 mt-8'>
            <PrestigeCard 
                img='particulier.jpeg'
                offset={true} 
                title='PARTICULIER' 
                subtitle='B Partner Prestige pour les particuliers exigeants dans leur gestion financière' 
                lign1='Carte de paiement internationale' 
                lign2='Des limites élevées sur vos paiements et virements'
                lign3='Accès au programme de fidélité B Partner'
                link='#'/>
            <PrestigeCard
                img='business.png'
                offset={false} 
                title='BUSINESS' 
                subtitle='B Partner Prestige Business pour les professionnels en quête d’autonomie et de flexibilité' 
                lign1='Paiements de vos facture dans plus de 30 devises' 
                lign2='Service de conciergerie pour vos déplacements'
                lign3='Des terminaux de paiement à votre disposition'
                link='#'/>
        </div>
      </div>
        {/* div nos offres */}
    </div>
    <Footer />
</div>
  )
}
