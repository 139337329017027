import React,{useState} from 'react'
import TopBar from '../components/TopBar'
import JoinUsBtn from '../components/JoinUsBtn'
import Caroussel from '../components/Caroussel'
import PrestigeCard from '../components/PrestigeCard'
import Value from '../components/Value'
import CardAdvantage from '../components/CardAdvantage'
import Footer from '../components/Footer'
import CommingSoon from '../components/CommingSoon'
export default function LandingPremium() {
  const [popup, setPopup] = useState(false);
  console.log(popup)
  const toggle = ()=>{
      setPopup(!popup);
  }
  return (
    <div className='flex flex-col'>


      <TopBar />


      {/* div video */}
      <div className='relative'>
      <video
        className='object-cover w-full h-screen'
        loop
        muted ={true}
        autoPlay={true}
        playsInline
        controls={false}
      >
          <source src={'/video4.mp4'} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white font-Poppins md:w-[43.72rem] lg:w-full lg:flex lg:flex-col lg:justify-center lg:items-center'>
          <h1 className='text-4xl w-80 leading-[3.5rem] font-normal tracking-wider font-Poppins md:w-full md:border-l-[3px] border-white md:ml-4 md:text-left md:pl-4 md:text-[3rem] md:mt-[19rem] lg:w-[48rem] lg:translate-x-16 lg:translate-y-14'>
            Découvrez B Partner{' '}<span className='text-pink-red'>{" "}PREMIUM, l'excellence</span>{' '}à votre image
          </h1>
          <p className='font-Poppins tracking-wider mt-8 md:mt-[17rem] md:text-left md:text-[1.5rem] md:p-4 md:w-[39rem] lg:w-[45rem] lg:self-start lg:ml-[5rem] lg:-translate-y-16'>
            <span className='md:text-[2rem]'>Un compte multi-devise sans <span className='md:text-pink-red'> {' '}frontieres<br className='hidden md:flex' /></span></span>
            {' '} accompagné de services innovants pour faciliter votre gestion financière
          </p>
        </div>
      </div>
      <CommingSoon popup={popup} toggle={toggle}/>
      {/* reste page */}
      <div className='bg-white px-5 flex flex-col items-center mt-[2rem] lg:px-14'>
        {/* detail offres */}
        <div className='flex flex-col items-center lg:flex-row lg:w-[80.4375rem] lg:mt-12 lg:justify-around lg:items-center '>
          <div className='md:w-[48rem] md:h-[36.5rem] md:flex md:flex-row md:justify-center md:items-center md:px-[2rem] md:mt-4 lg:w-[48rem] lg:h-full lg:mt-12 lg:px-0 lg:translate-y-6 lg:order-2 '>
            {/* card 1 text */}
            <div className='w-[21.375rem] pl-1 md:w-[23rem] md:h-[27.5rem] md:z-10 md:-translate-y-6 lg:w-[20rem] lg:h-[25.5rem] lg:order-2 lg:relative'>
              <div className='w-40 h-fit bg-pink-red p-[0.5rem] md:h-[27.375rem] md:w-[11.25rem] lg:w-[10.5625rem] lg:h-[26.5rem] lg:absolute lg:right-4'>
                <div className='w-80 bg-bp-grey h-[23.75rem] flex flex-col justify-center items-center md:h-[26.375rem] md:w-[22.0625rem] lg:w-[20.6875rem] lg:h-[25.5rem] lg:-translate-x-44'>
                  <div className=' w-[13rem] pl-2 lg:w-[15rem]'>
                    <h3 className='text-[1rem] lg:text-[1.25rem] text-white font-Poppins tracking-[3px] font-semibold  h-[2rem] text-left'>
                      Multi-Devise
                    </h3>
                    <p className='text-white font-Poppins text-[0.5rem] lg:text-[0.75rem] tracking-[3px] leading-[1rem] text-left '>
                      Des paiements en devises simplifiés
                    </p>
                  </div>

                  <div className=' w-[13rem] mt-[1rem] pl-2 lg:w-[15rem] lg:mt-8'>
                    <h3 className='text-[1rem] lg:text-[1.25rem] text-white font-Poppins tracking-[3px] font-semibold  h-[2rem] text-left'>
                      Voyages
                    </h3>
                    <p className='text-white font-Poppins text-[0.5rem] lg:text-[0.75rem] tracking-[3px] leading-[1rem] text-left '>
                    Profitez de votre accès au lounge offert
                    </p>
                  </div>

                  <div className=' w-[13rem] mt-[1rem] pl-2 lg:w-[15rem] lg:mt-8'>
                    <h3 className='text-[1rem] lg:text-[1.25rem] text-white font-Poppins tracking-[3px] font-semibold  h-[2rem] text-left'>
                      Cash-Back
                    </h3>
                    <p className='text-white font-Poppins text-[0.5rem] lg:text-[0.75rem] tracking-[3px] leading-[1rem] text-left '>
                      Soyez récompensés pour vos achats
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* card 1 img */}
            <div className='w-[21rem] px-1 mt-[1rem] md:w-[27.75rem] md:h-[33.5rem] lg:w-[25.1875rem] lg:h-full lg:-translate-x-16'>
              <img className="lg:-translate-y-8 lg:translate-x-24" src="img-card-1-premium.png" alt="Card 1" />
            </div>
          </div>
          {/* div nos offres */}
          <div className='w-[20.5rem] px-1 mt-[1rem] flex flex-col md:w-[38.125rem] md:-translate-x-8 justify-start md:mt-0 lg:w-[27rem] lg:pr-10 lg:translate-x-12'>
            <p className='w-[12rem] h-[3.5rem] flex items-center font-Poppins tracking-[0.2875rem] md:w-[12.125] md:[h-2.5rem] lg:w-[14rem]   uppercase'>Offre premium
            </p>
            <p className='text-[1.5rem] lg:text-[2rem] font-normal tracking-[0.2875rem] text-left leading-[3rem] border-l-[3px] pb-4 border-black pl-[1rem]'>
              Liberté Totale Et Sécurité Financière Assurée
            </p>
            <p className='w-[20.9375rem] mt-[1.5rem] mb-[1.5rem] font-DMSans text-left  p-0 md:w-full lg:mb-10'>
              Un compte et une carte de débit VISA pour un accès en toute liberté à votre argent. Des services à valeurs ajoutés afin de vous faciliter la vie.
            </p>
            <div className='w-fit' onClick={toggle}><JoinUsBtn text='NOUS REJOINDRE' /></div>
          </div>
        </div>
        <Caroussel
          title="NOS SERVICES COMPLEMENTAIRES PREMIUM"
          img1='img-crsl-7.jpeg'
          img2='bourse.png'
          img3='img-crsl-6.jpeg'
          p1="Envoyez de l’argent l’esprit tranquille avec notre system multi-devises"
          p2="Economisez du temps et de l'argent grâce à la conciergerie"
          p3="Sécurité et Facilité de paiements transfrontaliers" />
        <div className='mt-8 lg:mt-32'>
          <div className='w-full lg:px-40 md:flex md:flex-row'>
            <p className=' w-[20.9375rem] h-[4.0625rem] font-Poppins text-[1.5rem] font-semibold text-left mx-auto lg:w-[39.1875rem] lg:h-[9rem]  lg:text-[2.5rem]'>
            Premium, l'efficacité au quotidien
            </p>
            <p className='w-[20.9375rem] h-[5] text-left text-[1.125rem] font-DMSans mx-auto mt-[2rem] lg:mt-3 lg:w-[32.4375rem] lg:h-[6.6875rem] lg:flex lg:font-medium'>
              En tout autonomie, réaliser des paiements SEPA en euro, des paiements instantanés ou des paiements en devise avec B Partner Premium
            </p>
          </div>
          <div className='mt-[1.25rem] flex flex-col justify-center items-center bg-[#f5f5f6] md:flex-row md:h-fit md:mt-8 lg:h-[27.5rem] lg:mt-12'>
            <div className='w-[18.3125rem] h-[12.125rem] relative mt-4 md:order-2  lg:h-[21.9375rem] lg:w-[32.5rem]'>
              <div className='bg-pink-red h-full w-1/2 absolute right-0'></div>
              <img className='w-[17.3125rem] h-[11.125rem] z-1 absolute top-2 right-2 lg:w-[32.375rem] lg:h-[20.9375rem]' src="carte-dupont-premium.png"></img>
            </div>
            <div className=' mt-4 px-2 md:order-1 md:w-1/2  lg:h-full lg:mt-24 lg:w-2/5 lg:mr-16'>
              <p className='font-Poppins text-[1.5rem] font-semibold text-left lg:w-[30rem] lg:h-[4.0625rem] lg:text-[1.6875rem]'>
              Choisir B Partner Premium
              </p>
              <p className='font-DMSans text-[1.125rem] text-left mb-8 mt-2 lg:mt-8'>
              B Partner Premium pour les particuliers et les professionnels est une offre qui permet de combiner des solutions de paiements dynamiques, et internationales, avec des services à valeurs ajoutés qui facilite la gestion de vos finances. Le compte B Partner offre un condensé de services innovants et à forte valeur pour vous satisfaire
              </p>
            </div>
          </div>
        </div>
        <div className='w-screen h-[39.0625rem] bg-[#0e0e0e] mt-8 relative px-6 flex flex-col justify-start md:mt-12 lg:pl-24 lg:mt-20'>
          <p className='text-white text-left font-Poppins text-[1.5rem] w-full font-medium mt-8 md:w-3/4 md:text-[2rem] lg:w-[32.4375rem] lg:mt-24'>
            Simplifiez vous la vie et adaptez une solution de{" "} <span className='text-pink-red font-bold'>gestion financière efficace</span>, sans tracas, tout en étant sécurisée.
          </p>
          <p className='text-white text-base font-DMSans text-left mt-4 leading-7 pb-4 md:w-[60%] md:mt-12 md:text-[1.125rem] lg:w-[32.4375rem] lg:mt-16'>
            Une gestion de vos finances, en proximité, avec des conseillers à votre écoute et disponibles.
          </p>
          <button onClick={toggle} className='bg-white w-[10.0625rem] p-2 font-DMSans md:font-semibold md:mt-12 md:w-[13rem] md:p-3 md:text-[1.125rem] flex flex-row justify-center items-center'>Souscrire <img className='ml-8' src='r-fleche.png' alt='fleche'></img></button>
          <img src='digital-card.png' alt='digital card' className='absolute bottom-0 right-0 w-[19.8125rem] lg:w-1/2' />
        </div>

        {/* div nos offres */}
        <div className=' lg:flex lg:flex-row lg:w-[80rem] lg:px-16 lg:justify-around lg:mt-24'>
          <div className='w-[20.5rem] px-1 mt-[1rem] flex flex-col md:w-[38.125rem] md:-translate-x-8 md:mt-12 justify-start md:ml-8 lg:w-[26.625rem] lg:justify-center lg:mr-16'>
            <p className='w-[10rem] h-[3.5rem] flex items-center font-Poppins tracking-[0.2875rem] md:w-[12.125] md:[h-2.5rem] uppercase lg:text-xl lg:w-[14rem]'>NOS VALEURS
            </p>
            <p className='text-[1.5rem] lg:text-[2rem] font-normal tracking-[0.2875rem] text-left leading-[3rem] border-l-[3px] pb-4 border-black pl-[1rem]'>
              B Partner, l'excellence dans la finance, redéfinie
            </p>
            <p className='w-[20.9375rem] mt-[1rem]  font-DMSans text-left p-0 md:w-full md:mb-6 lg:text-[1.125rem] mb-6'>
              L'Excellence et la qualité sont nos mots d'ordre quand il s'agit de vous aider à gérer vos finances.
            </p>
            <p className='w-[20.9375rem]  font-DMSans text-left p-0 md:w-full md:mb-6 lg:text-[1.125rem] mb-6'>
              Nos certifications ISO renforcent nos capacités et soutiennent vos projets tout en sécurisant vos données
            </p>
            <div className='w-fit' onClick={toggle}><JoinUsBtn text='REJOIGNEZ NOUS' /></div>
          </div>
          {/* Valeurs */}
          <div className='md:grid md:grid-cols-3 md:w-full md:mt-4 lg:w-[55%]'>
            <Value
              number='01'
              title='Securité'
              text='Conçue pour un monde en évolution.'
            />
            <Value
              number='02'
              title='Proximité'
              text='Dans vos usages du quotidien'
            />
            <Value
              number='03'
              title='Tranquilité'
              text="Pour gérer vos paiements et finances"
            />
            <Value
              number='04'
              title='Confidentialité'
              text='Et protection sans concession'
            />
            <Value
              number='05'
              title='Personalisation'
              text='Des services et produits'
            />
            <Value
              number='06'
              title='Exclusivité'
              text='Des solutions uniques, et inédites'
            />
          </div>
        </div>
        <div className='flex flex-col lg:flex lg:flex-row lg:mt-12 lg:w-[80rem] lg:px-16 lg:justify-around '>
          <div className='w-[20.5rem] px-1 mt-[1rem] flex flex-col md:w-[38.125rem] md:-translate-x-8 md:mt-12 justify-start md:ml-8 lg:w-[26.625rem] md:hidden lg:flex lg:justify-center order-2 lg:order-1'>
            <p className='w-[10rem] h-[3.5rem] flex items-center font-Poppins tracking-[0.2875rem] md:w-[12.125] md:[h-2.5rem] uppercase lg:text-xl lg:w-[14rem] hidden'>
            </p>
            <p className='text-[1.5rem] lg:text-[2rem] font-normal tracking-[0.2875rem] text-left leading-[3rem] border-l-[3px] pb-4 border-black pl-[1rem]'>
              B Partner PREMIUM: pour particuliers et profesionnels
            </p>
            <p className='w-[20.9375rem] mt-[1rem]  font-DMSans text-left p-0 md:w-full md:mb-6 lg:text-[1.125rem] mb-6 lg:mb-10'>
              B Partner Premium est une offre de gestion de vos dépenses, simple, et efficace et en toute autonomie.
            </p>
            <div className='w-fit' onClick={toggle}><JoinUsBtn text='PRENDRE RENDEZ-VOUS' /></div>
          </div>
          {/* cards */}
          <div className='mt-8 md:mt-12 md:flex md:flex-row order-1 lg:order-2'>
            <PrestigeCard
              img='particulier2.jpeg'
              offset={true}
              title='PARTICULIER'
              subtitle='B Partner Premium pour les particuliers en quête de liberté financière'
              lign1='Carte de paiement à l’internationale'
              lign2='Virements et paiements en devise'
              lign3='Une gestion en ligne de vos plafonds de dépenses'
              link='#' />
            <PrestigeCard
              img='business2.jpeg'
              offset={false}
              title='BUSINESS'
              subtitle='B Partner Premium Business pour les professionnels en quête d’autonomie et de flexibilité'
              lign1='Paiements de vos factures dans plus de 30 devises'
              lign2='Une gestion de vos dépenses simplifiée'
              lign3='Services complémentaires, conseiller à votre écoute'
              link='#' />
          </div>
        </div>

      </div>
      <Footer />
    </div>
  )
}
